<template>
  <section class="honoree-form">
    <h2 class="honoree-form__title">
      {{ translations.title }}
    </h2>
    <form ref="form" @submit.prevent="handleFormSubmit">
      <fieldset class="honoree-form__subsection">
        <legend>{{ translations.areYouNominatingYourself.label }}</legend>
        <div class="honoree-form__radio-group">
          <label for="nominating-myself-yes" class="honoree-form__checkbox__wrapper">
            <input
              id="nominating-myself-yes"
              v-model="isSelf"
              class="honoree-form__checkbox__input"
              type="radio"
              name="are-you-nominating-yourself"
              :value="true"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.areYouNominatingYourself.answers.yes.label }}
          </label>
          <label for="nominating-myself-no" class="honoree-form__checkbox__wrapper">
            <input
              id="nominating-myself-no"
              v-model="isSelf"
              class="honoree-form__checkbox__input"
              type="radio"
              name="are-you-nominating-yourself"
              :value="false"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.areYouNominatingYourself.answers.no.label }}
          </label>
        </div>
      </fieldset>

      <hr class="honoree-form__hr" />

      <transition name="slide-fade">
        <fieldset v-if="!isSelf" class="honoree-form__subsection row">
          <legend class="honoree-form__subsection__title honoree-form__subsection__title col-lg-12">
            {{ translations.fieldSections.yourContactInfoSection.title }}
          </legend>
          <HonoreeFormSelectField
            id="my-title"
            v-model="formData.nominatorInfo.title"
            :model-value="formData.nominatorInfo.title"
            class="col-lg-2"
            :label="translations.fieldSections.yourContactInfoSection.fields.title.label"
            :initial-data="initialData.data.yourContactInfo.title"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.title.requiredFieldMessage
            "
            :options="settings.titleOptions"
            :default-option="settings.selectDefaultOption"
            @update:modelValue="(newValue) => (formData.nominatorInfo.title = newValue)"
          />
          <HonoreeFormInputField
            id="my-first-name"
            v-model="formData.nominatorInfo.firstName"
            :model-value="formData.nominatorInfo.firstName"
            class="col-lg-4"
            :label="translations.fieldSections.yourContactInfoSection.fields.firstName.label"
            :initial-data="initialData.data.yourContactInfo.firstName"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.firstName
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.firstName.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.firstName.maxLength.message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.firstName = newValue)"
          />
          <HonoreeFormInputField
            id="my-last-name"
            v-model="formData.nominatorInfo.lastName"
            :model-value="formData.nominatorInfo.lastName"
            class="col-lg-6"
            :label="translations.fieldSections.yourContactInfoSection.fields.lastName.label"
            :initial-data="initialData.data.yourContactInfo.lastName"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.lastName.requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.lastName.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.lastName.maxLength.message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.lastName = newValue)"
          />
          <HonoreeFormInputField
            id="my-primary-phone"
            v-model="formData.nominatorInfo.homePhone"
            :model-value="formData.nominatorInfo.homePhone"
            class="col-lg-4"
            :label="translations.fieldSections.yourContactInfoSection.fields.primaryPhone.label"
            :initial-data="initialData.data.yourContactInfo.primaryPhone"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.primaryPhone
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.primaryPhone.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.primaryPhone.maxLength
                .message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.homePhone = newValue)"
          />
          <HonoreeFormInputField
            id="my-work-phone"
            v-model="formData.nominatorInfo.workPhone"
            :model-value="formData.nominatorInfo.workPhone"
            class="col-lg-4"
            :label="translations.fieldSections.yourContactInfoSection.fields.workPhone.label"
            :initial-data="initialData.data.yourContactInfo.workPhone"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.workPhone
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.workPhone.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.workPhone.maxLength.message
            "
            :is-required="false"
            @update:modelValue="(newValue) => (formData.nominatorInfo.workPhone = newValue)"
          />
          <HonoreeFormInputField
            id="my-fax"
            v-model="formData.nominatorInfo.fax"
            :model-value="formData.nominatorInfo.fax"
            class="col-lg-4"
            :label="translations.fieldSections.yourContactInfoSection.fields.fax.label"
            :initial-data="initialData.data.yourContactInfo.fax"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.fax.requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.fax.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.fax.maxLength.message
            "
            :is-required="false"
            @update:modelValue="(newValue) => (formData.nominatorInfo.fax = newValue)"
          />
          <HonoreeFormInputField
            id="my-street-address"
            v-model="formData.nominatorInfo.addressLine1"
            :model-value="formData.nominatorInfo.addressLine1"
            class="col-lg-9"
            :label="translations.fieldSections.yourContactInfoSection.fields.streetAddress.label"
            :initial-data="initialData.data.yourContactInfo.streetAddress"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.streetAddress
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.streetAddress.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.streetAddress.maxLength
                .message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.addressLine1 = newValue)"
          />
          <HonoreeFormInputField
            id="my-apt-suite"
            v-model="formData.nominatorInfo.addressLine2"
            :model-value="formData.nominatorInfo.addressLine2"
            class="col-lg-3"
            :label="translations.fieldSections.yourContactInfoSection.fields.aptOrSuite.label"
            :initial-data="initialData.data.yourContactInfo.aptOrSuite"
            :is-required="false"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.aptOrSuite
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.aptOrSuite.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.aptOrSuite.maxLength.message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.addressLine2 = newValue)"
          />
          <HonoreeFormInputField
            id="my-city"
            v-model="formData.nominatorInfo.city"
            :model-value="formData.nominatorInfo.city"
            class="col-lg-6"
            :label="translations.fieldSections.yourContactInfoSection.fields.city.label"
            :initial-data="initialData.data.yourContactInfo.city"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.city.requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.city.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.city.maxLength.message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.city = newValue)"
          />
          <HonoreeFormSelectField
            id="my-state"
            v-model="formData.nominatorInfo.stateCode"
            :model-value="formData.nominatorInfo.stateCode"
            class="col-lg-3"
            :label="translations.fieldSections.yourContactInfoSection.fields.state.label"
            :initial-data="initialData.data.yourContactInfo.state"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.state.requiredFieldMessage
            "
            :options="settings.stateCodes"
            :default-option="settings.selectDefaultOption"
            @update:modelValue="(newValue) => (formData.nominatorInfo.stateCode = newValue)"
          />
          <HonoreeFormInputField
            id="my-zip-code"
            v-model="formData.nominatorInfo.zip"
            :model-value="formData.nominatorInfo.zip"
            class="col-lg-3"
            :label="translations.fieldSections.yourContactInfoSection.fields.zipCode.label"
            :initial-data="initialData.data.yourContactInfo.zipCode"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.zipCode.requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.zipCode.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.zipCode.maxLength.message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.zip = newValue)"
          />
          <HonoreeFormInputField
            id="my-organization-name"
            v-model="formData.nominatorInfo.organizationName"
            :model-value="formData.nominatorInfo.organizationName"
            class="col-lg-12"
            :label="translations.fieldSections.yourContactInfoSection.fields.organizationName.label"
            :initial-data="initialData.data.yourContactInfo.organizationName"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.organizationName
                .requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.organizationName.maxLength
                .count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.organizationName.maxLength
                .message
            "
            :is-required="false"
            @update:modelValue="(newValue) => (formData.nominatorInfo.organizationName = newValue)"
          />
          <HonoreeFormInputField
            id="my-job-title"
            v-model="formData.nominatorInfo.jobTitle"
            :model-value="formData.nominatorInfo.jobTitle"
            class="col-lg-6"
            :label="translations.fieldSections.yourContactInfoSection.fields.jobTitle.label"
            :initial-data="initialData.data.yourContactInfo.jobTitle"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.jobTitle.requiredFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.jobTitle.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.jobTitle.maxLength.message
            "
            :is-required="false"
            @update:modelValue="(newValue) => (formData.nominatorInfo.jobTitle = newValue)"
          />
          <HonoreeFormInputField
            id="my-email-address"
            v-model="formData.nominatorInfo.email"
            :model-value="formData.nominatorInfo.email"
            class="col-lg-6"
            :label="translations.fieldSections.yourContactInfoSection.fields.emailAddress.label"
            :initial-data="initialData.data.yourContactInfo.emailAddress"
            :error-message="
              translations.fieldSections.yourContactInfoSection.fields.emailAddress
                .requiredFieldMessage
            "
            :is-email="true"
            :invalid-message="
              translations.fieldSections.yourContactInfoSection.fields.emailAddress
                .invalidFieldMessage
            "
            :character-limit="
              translations.fieldSections.yourContactInfoSection.fields.emailAddress.maxLength.count
            "
            :max-character-message="
              translations.fieldSections.yourContactInfoSection.fields.emailAddress.maxLength
                .message
            "
            @update:modelValue="(newValue) => (formData.nominatorInfo.email = newValue)"
          />
          <!---related to nominee s  -->
          <fieldset class="honoree-form__subsection">
            <legend class="wow-nomination-form-legend-hide"></legend>
            <div class="col-lg-12">
              <span
                class="honoree-form__subsection__subtitle honoree-form__subsection__subtitle--paragraph"
              >
                {{ translations.fieldSections.nomineeRelationSection.question }}
              </span>

              <!--  radio button -->
              <div class="honoree-form__radio-group -vertical">
                <label
                  v-for="(answer, i) in nomineeRelationOptions"
                  :key="answer.id"
                  class="honoree-form__checkbox__wrapper -nest"
                >
                  <input
                    :id="answer.id"
                    v-model="honoreeRelated"
                    class="honoree-form__checkbox__input"
                    name="honoree-related"
                    type="radio"
                    :value="answer.value"
                    :aria-invalid="!radioSectionIsValid"
                    @change="updateRadiosSectionState"
                  />
                  <span class="honoree-form__checkbox__checkmark" />
                  {{ answer.label }}
                  <HonoreeFormInputField
                    v-if="
                      otherRelatedVisible &&
                      i === translations.fieldSections.nomineeRelationSection.answers.length - 1
                    "
                    id="pleaseDescribe"
                    v-model="formData.nomineeInfo.pleaseDescribe"
                    class="col-lg-3 -nested"
                    :label="labelPleaseDescribe"
                    :initial-data="initialData.data.nomineeContactInfo.pleaseDescribe"
                    :error-message="pleaseDescribeError"
                    :character-limit="
                      translations.fieldSections.nomineeRelationSection.maxLength.count
                    "
                    :max-character-message="
                      translations.fieldSections.nomineeRelationSection.maxLength.message
                    "
                  />
                </label>
              </div>

              <!--radio button-->
            </div>
            <span
              v-if="!radioSectionIsValid"
              id="personal-page-related-section--error"
              class="honoree-form__error-state"
            >
              {{ translations.fieldSections.nomineeRelationSection.requiredFieldMessage }}
            </span>
          </fieldset>
          <!--related to nominee e-->
          <hr class="honoree-form__hr col-lg-12" />
        </fieldset>
      </transition>

      <fieldset class="honoree-form__subsection row">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title col-lg-12">
          {{ translations.fieldSections.contactInfoSection.title }}
        </legend>
        <HonoreeFormSelectField
          id="honoree-title"
          v-model="formData.nomineeInfo.title"
          class="col-lg-2"
          :label="translations.fieldSections.contactInfoSection.fields.title.label"
          :initial-data="initialData.data.nomineeContactInfo.title"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.title.requiredFieldMessage
          "
          :options="settings.titleOptions"
          :default-option="settings.selectDefaultOption"
        />
        <HonoreeFormInputField
          id="honoree-first-name"
          v-model="formData.nomineeInfo.firstName"
          class="col-lg-4"
          :label="translations.fieldSections.contactInfoSection.fields.firstName.label"
          :initial-data="initialData.data.nomineeContactInfo.firstName"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.firstName.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.firstName.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.firstName.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-last-name"
          v-model="formData.nomineeInfo.lastName"
          class="col-lg-6"
          :label="translations.fieldSections.contactInfoSection.fields.lastName.label"
          :initial-data="initialData.data.nomineeContactInfo.lastName"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.lastName.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.lastName.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.lastName.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-primary-phone"
          v-model="formData.nomineeInfo.homePhone"
          class="col-lg-4"
          :label="translations.fieldSections.contactInfoSection.fields.primaryPhone.label"
          :initial-data="initialData.data.nomineeContactInfo.primaryPhone"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.primaryPhone.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.primaryPhone.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.primaryPhone.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-work-phone"
          v-model="formData.nomineeInfo.workPhone"
          class="col-lg-4"
          :label="translations.fieldSections.contactInfoSection.fields.workPhone.label"
          :initial-data="initialData.data.nomineeContactInfo.workPhone"
          :is-required="false"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.workPhone.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.workPhone.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.workPhone.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-fax"
          v-model="formData.nomineeInfo.fax"
          class="col-lg-4"
          :label="translations.fieldSections.contactInfoSection.fields.fax.label"
          :initial-data="initialData.data.nomineeContactInfo.fax"
          :is-required="false"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.fax.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.fax.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.fax.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-street-address"
          v-model="formData.nomineeInfo.addressLine1"
          class="col-lg-9"
          :label="translations.fieldSections.contactInfoSection.fields.streetAddress.label"
          :initial-data="initialData.data.nomineeContactInfo.streetAddress"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.streetAddress.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.streetAddress.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.streetAddress.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-apt-suite"
          v-model="formData.nomineeInfo.addressLine2"
          class="col-lg-3"
          :label="translations.fieldSections.contactInfoSection.fields.aptOrSuite.label"
          :initial-data="initialData.data.nomineeContactInfo.aptOrSuite"
          :is-required="false"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.aptOrSuite.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.aptOrSuite.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.aptOrSuite.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-city"
          v-model="formData.nomineeInfo.city"
          class="col-lg-6"
          :label="translations.fieldSections.contactInfoSection.fields.city.label"
          :initial-data="initialData.data.nomineeContactInfo.city"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.city.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.city.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.city.maxLength.message
          "
        />
        <HonoreeFormSelectField
          id="honoree-state"
          v-model="formData.nomineeInfo.stateCode"
          class="col-lg-3"
          :label="translations.fieldSections.contactInfoSection.fields.state.label"
          :initial-data="initialData.data.nomineeContactInfo.state"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.state.requiredFieldMessage
          "
          :options="settings.stateCodes"
          :default-option="settings.selectDefaultOption"
        />
        <HonoreeFormInputField
          id="honoree-zip-code"
          v-model="formData.nomineeInfo.zip"
          class="col-lg-3"
          :label="translations.fieldSections.contactInfoSection.fields.zipCode.label"
          :initial-data="initialData.data.nomineeContactInfo.zipCode"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.zipCode.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.zipCode.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.zipCode.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-job-title"
          v-model="formData.nomineeInfo.jobTitle"
          class="col-lg-6"
          :label="translations.fieldSections.contactInfoSection.fields.jobTitle.label"
          :initial-data="initialData.data.nomineeContactInfo.jobTitle"
          :is-required="false"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.jobTitle.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.jobTitle.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.jobTitle.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="honoree-email-address"
          v-model="formData.nomineeInfo.email"
          class="col-lg-6"
          :label="translations.fieldSections.contactInfoSection.fields.emailAddress.label"
          :initial-data="initialData.data.nomineeContactInfo.emailAddress"
          :is-email="true"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.emailAddress.requiredFieldMessage
          "
          :invalid-message="
            translations.fieldSections.contactInfoSection.fields.emailAddress.invalidFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.emailAddress.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.emailAddress.maxLength.message
          "
        />
        <HonoreeFormSelectField
          id="honoree-age-range"
          v-model="formData.nomineeInfo.ageRangeId"
          class="col-lg-4"
          :label="translations.fieldSections.contactInfoSection.fields.ageRange.label"
          :initial-data="initialData.data.nomineeContactInfo.ageRange"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.ageRange.requiredFieldMessage
          "
          :options="settings.ageRangeOptions"
          :default-option="settings.selectDefaultOption"
        />
        <HonoreeFormInputField
          id="honoree-website"
          v-model="formData.nomineeInfo.website"
          class="col-lg-8"
          :label="translations.fieldSections.contactInfoSection.fields.website.label"
          :initial-data="initialData.data.nomineeContactInfo.website"
          :is-required="false"
          :error-message="
            translations.fieldSections.contactInfoSection.fields.website.requiredFieldMessage
          "
          :character-limit="
            translations.fieldSections.contactInfoSection.fields.website.maxLength.count
          "
          :max-character-message="
            translations.fieldSections.contactInfoSection.fields.website.maxLength.message
          "
        />

        <!--Organization fields s -->
        <HonoreeFormInputField
          id="organization-name"
          v-model="formData.nomineeInfo.OrganizationName"
          class="col-lg-12"
          :label="translations.organizationDetails.organizationName.label"
          :initial-data="initialData.data.nomineeContactInfo.organizationName"
          :is-required="true"
          :error-message="translations.organizationDetails.organizationName.requiredFieldMessage"
          :character-limit="translations.organizationDetails.organizationName.maxLength.count"
          :max-character-message="
            translations.organizationDetails.organizationName.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="organization-website"
          v-model="formData.nomineeInfo.OrganizationWebsite"
          class="col-lg-12"
          :label="translations.organizationDetails.organizationWebsite.label"
          :initial-data="initialData.data.nomineeContactInfo.organizationwebsite"
          :is-required="false"
          :error-message="translations.organizationDetails.organizationWebsite.requiredFieldMessage"
          :character-limit="translations.organizationDetails.organizationWebsite.maxLength.count"
          :max-character-message="
            translations.organizationDetails.organizationWebsite.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="organization-role"
          v-model="formData.nomineeInfo.OrganizationRole"
          class="col-lg-6"
          :label="translations.organizationDetails.organizationRole.label"
          :initial-data="initialData.data.nomineeContactInfo.organizationrole"
          :is-required="false"
          :error-message="translations.organizationDetails.organizationRole.requiredFieldMessage"
          :character-limit="translations.organizationDetails.organizationRole.maxLength.count"
          :max-character-message="
            translations.organizationDetails.organizationRole.maxLength.message
          "
        />
        <HonoreeFormInputField
          id="organization-phone"
          v-model="formData.nomineeInfo.Organizationphone"
          class="col-lg-6"
          :label="translations.organizationDetails.phone.label"
          :initial-data="initialData.data.nomineeContactInfo.organizationphone"
          :is-required="false"
          :error-message="translations.organizationDetails.phone.requiredFieldMessage"
          :character-limit="translations.organizationDetails.phone.maxLength.count"
          :max-character-message="translations.organizationDetails.phone.maxLength.message"
        />
        <HonoreeFormSelectField
          id="organization-city-State"
          v-model="formData.nomineeInfo.OrganizationState"
          class="col-lg-6"
          :label="translations.organizationDetails.state.label"
          :initial-data="
            initialData.data.nomineeContactInfo.organizationstate
              ? initialData.data.nomineeContactInfo.organizationstate
              : ''
          "
          :is-required="false"
          :error-message="translations.organizationDetails.state.requiredFieldMessage"
          :options="settings.stateCodes"
          :default-option="settings.selectDefaultOption"
        />

        <HonoreeFormInputField
          id="organization-social-media-link"
          v-model="formData.nomineeInfo.Socialmedialink"
          class="col-lg-6"
          :label="translations.organizationDetails.facebookLink.label"
          :initial-data="initialData.data.nomineeContactInfo.socialmedialink"
          :is-required="false"
          :error-message="translations.organizationDetails.facebookLink.requiredFieldMessage"
          :character-limit="translations.organizationDetails.facebookLink.maxLength.count"
          :max-character-message="translations.organizationDetails.facebookLink.maxLength.message"
        />
        <HonoreeFormTextAreaField
          id="organization-missions"
          v-model="formData.nomineeInfo.Organizationdescription"
          class="col-lg-12"
          :label="translations.organizationDetails.organizationMission.label"
          :is-required="false"
          :initial-data="initialData.data.nomineeContactInfo.organizationdescription"
          :character-counter="translations.organizationDetails.organizationMission.helpText"
          :error-message="translations.organizationDetails.organizationMission.requiredFieldMessage"
        />

        <!-- Organization fields e-->
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title--paragraph">
          {{ translations.fieldSections.compensationSection.question }}
        </legend>
        <span
          v-for="(answer, $key) in translations.fieldSections.compensationSection.answer"
          :key="$key"
          class="honoree-form__subsection__subtitle"
        >
          {{ answer }}
        </span>
        <div class="honoree-form__radio-group">
          <label for="compensation-salary" class="honoree-form__checkbox__wrapper">
            <input
              id="compensation-salary"
              v-model="compensation"
              class="honoree-form__checkbox__input"
              name="honoree-compensation"
              type="radio"
              value="SALARY"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.compensationSection.options.salary.label }}
          </label>
          <label for="compensation-stipend" class="honoree-form__checkbox__wrapper">
            <input
              id="compensation-stipend"
              :key="translations.fieldSections.compensationSection.options.stipend.value"
              v-model="compensation"
              class="honoree-form__checkbox__input"
              name="honoree-compensation"
              type="radio"
              value="STIPEND"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.compensationSection.options.stipend.label }}
          </label>
          <label for="compensation-no-compensation" class="honoree-form__checkbox__wrapper">
            <input
              id="compensation-no-compensation"
              :key="translations.fieldSections.compensationSection.options.noCompensation.value"
              v-model="compensation"
              class="honoree-form__checkbox__input"
              name="honoree-compensation"
              type="radio"
              value="NO_COMPENSATION"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.compensationSection.options.noCompensation.label }}
          </label>
        </div>
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection">
        <legend class="honoree-form__subsection__title honoree-form__subsection__title--paragraph">
          {{ translations.fieldSections.currentStatusSection.question }}
        </legend>
        <span
          v-for="(answer, $key) in translations.fieldSections.currentStatusSection.answers"
          :key="$key"
          class="honoree-form__subsection__subtitle"
        >
          {{ answer }}
        </span>
        <div class="honoree-form__radio-group">
          <label for="current-status-yes" class="honoree-form__checkbox__wrapper">
            <input
              id="current-status-yes"
              v-model="formData.nomineeInfo.serviceNow"
              class="honoree-form__checkbox__input"
              name="current-status"
              type="radio"
              :value="true"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.currentStatusSection.options.yes.label }}
          </label>
          <label for="current-status-no" class="honoree-form__checkbox__wrapper">
            <input
              id="current-status-no"
              v-model="formData.nomineeInfo.serviceNow"
              class="honoree-form__checkbox__input"
              name="current-status"
              type="radio"
              :value="false"
            />
            <span class="honoree-form__checkbox__checkmark" />
            {{ translations.fieldSections.currentStatusSection.options.no.label }}
          </label>
        </div>
        <transition v-if="!formData.nomineeInfo.serviceNow" name="slide-fade">
          <p class="honoree-form__warning-message">
            {{ translations.fieldSections.currentStatusSection.edge.label }}
          </p>
        </transition>
        <HonoreeFormInputField
          v-if="formData.nomineeInfo.serviceNow"
          id="current-status-payment-value"
          v-model="formData.nomineeInfo.currentPayment"
          class="top-spacing"
          :label="translations.fieldSections.currentPaymentSubSection.label"
          :initial-data="initialData.data.nomineeContactInfo.currentPayment"
          :is-required="false"
          :error-message="translations.fieldSections.currentPaymentSubSection.requiredFieldMessage"
          :character-limit="translations.fieldSections.currentPaymentSubSection.maxLength.count"
          :max-character-message="
            translations.fieldSections.currentPaymentSubSection.maxLength.message
          "
        />
      </fieldset>

      <hr class="honoree-form__hr" />

      <fieldset class="honoree-form__subsection row">
        <legend
          class="honoree-form__subsection__title honoree-form__subsection__title--paragraph col-lg-12"
        >
          {{ translations.fieldSections.timelineSubSection.startingInfo.question }}
        </legend>
        <span
          v-for="(answer, $key) in translations.fieldSections.timelineSubSection.startingInfo
            .answers"
          :key="$key"
          class="honoree-form__subsection__subtitle col-lg-12"
        >
          {{ answer }}
        </span>

        <HonoreeFormSelectField
          id="starting-month"
          v-model="formData.nomineeInfo.serviceMonth"
          class="col-6 col-lg-2"
          :label="translations.fieldSections.timelineSubSection.startingInfo.fields.month.label"
          :initial-data="initialData.data.serviceBegin.month"
          :error-message="
            translations.fieldSections.timelineSubSection.startingInfo.fields.month
              .requiredFieldMessage
          "
          :options="settings.monthValues"
          :default-option="settings.selectDefaultOption"
        />

        <HonoreeFormSelectField
          id="starting-year"
          v-model="formData.nomineeInfo.serviceYear"
          class="col-6 col-lg-2"
          :label="translations.fieldSections.timelineSubSection.startingInfo.fields.year.label"
          :initial-data="initialData.data.serviceBegin.year"
          :error-message="
            translations.fieldSections.timelineSubSection.startingInfo.fields.year
              .requiredFieldMessage
          "
          :options="yearsArray"
          :default-option="settings.selectDefaultOption"
        />
      </fieldset>

      <fieldset class="honoree-form__subsection row">
        <legend
          class="honoree-form__subsection__title honoree-form__subsection__title--paragraph col-lg-12"
        >
          {{ translations.fieldSections.timelineSubSection.durationInfo.question }}
        </legend>
        <span
          v-for="(answer, $key) in translations.fieldSections.timelineSubSection.durationInfo
            .answers"
          :key="$key"
          class="honoree-form__subsection__subtitle col-lg-12"
        >
          {{ answer }}
        </span>
        <HonoreeFormSelectField
          id="devoted-hours"
          v-model="formData.nomineeInfo.hours"
          class="col-lg-4"
          :label="translations.fieldSections.timelineSubSection.durationInfo.fields.duration.label"
          :initial-data="initialData.data.devotedHours"
          :error-message="
            translations.fieldSections.timelineSubSection.durationInfo.fields.duration
              .requiredFieldMessage
          "
          :options="settings.devotedHoursOptions"
          :default-option="settings.selectDefaultOption"
        />
      </fieldset>

      <hr class="honoree-form__hr" />

      <div class="honoree-form__buttons-wrapper">
        <button
          class="wow-button wow-button--black"
          type="button"
          @click.prevent="handleFormSave"
          v-text="formLabels.saveButton.label"
        />
        <button
          :disabled="!formData.nomineeInfo.serviceNow"
          class="wow-button wow-button--black"
          :class="{ 'wow-button--disabled': !formData.nomineeInfo.serviceNow }"
          @click="triggerValidation"
          v-text="`${formLabels.nextButton.label} >`"
        />
      </div>
      <div>
        <a
          class="wow-button"
          type="button"
          :href="settings.linkToNominationPage"
          v-text="`< ${formLabels.returnToNominations.label}`"
        />
      </div>
    </form>
  </section>
</template>

<script>
import { nextTick } from 'vue';
import eventBus from '@loreal/eventbus-js';
import HonoreeFormInputField from './HonoreeFormInputField.vue';
import HonoreeFormTextAreaField from './HonoreeFormTextAreaField.vue';
import HonoreeFormSelectField from './HonoreeFormSelectField.vue';
import { VALIDATE_CHILD_EVENT, axiosPostWrapper, PERSONAL_PAGE, QUESTIONS_PAGE } from './settings';

export default {
  name: 'HonoreeFormPersonalPage',
  components: {
    HonoreeFormInputField,
    HonoreeFormSelectField,
    HonoreeFormTextAreaField,
  },
  props: {
    allowedSteps: {
      required: true,
      type: Number,
    },
    settings: {
      required: true,
      type: Object,
    },
    translations: {
      required: true,
      type: Object,
    },
    formLabels: {
      required: true,
      type: Object,
    },
    formId: {
      required: true,
      type: String,
    },
    initialData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isSelf: this.initialData.data.nominatingYourself,
      otherRelatedVisible: false,
      formData: {
        nominatorInfo: {
          title: this.initialData.data.yourContactInfo.title,
          firstName: this.initialData.data.yourContactInfo.firstName,
          lastName: this.initialData.data.yourContactInfo.lastName,
          homePhone: this.initialData.data.yourContactInfo.primaryPhone,
          workPhone: this.initialData.data.yourContactInfo.workPhone,
          fax: this.initialData.data.yourContactInfo.fax,
          addressLine1: this.initialData.data.yourContactInfo.streetAddress,
          addressLine2: this.initialData.data.yourContactInfo.aptOrSuite,
          city: this.initialData.data.yourContactInfo.city,
          stateCode: this.initialData.data.yourContactInfo.state,
          zip: this.initialData.data.yourContactInfo.zipCode,
          organizationName: this.initialData.data.yourContactInfo.organizationName,
          jobTitle: this.initialData.data.yourContactInfo.jobTitle,
          email: this.initialData.data.yourContactInfo.emailAddress,
        },
        nomineeInfo: {
          title: this.initialData.data.nomineeContactInfo.title,
          firstName: this.initialData.data.nomineeContactInfo.firstName,
          lastName: this.initialData.data.nomineeContactInfo.lastName,
          homePhone: this.initialData.data.nomineeContactInfo.primaryPhone,
          workPhone: this.initialData.data.nomineeContactInfo.workPhone,
          fax: this.initialData.data.nomineeContactInfo.fax,
          addressLine1: this.initialData.data.nomineeContactInfo.streetAddress,
          addressLine2: this.initialData.data.nomineeContactInfo.aptOrSuite,
          city: this.initialData.data.nomineeContactInfo.city,
          stateCode: this.initialData.data.nomineeContactInfo.state,
          zip: this.initialData.data.nomineeContactInfo.zipCode,
          jobTitle: this.initialData.data.nomineeContactInfo.jobTitle,
          email: this.initialData.data.nomineeContactInfo.emailAddress,
          ageRangeId: this.initialData.data.nomineeContactInfo.ageRange,
          website: this.initialData.data.nomineeContactInfo.webSite,
          serviceYear: this.initialData.data.serviceBegin.year,
          serviceMonth: this.initialData.data.serviceBegin.month,
          hours: this.initialData.data.devotedHours,
          serviceNow: this.initialData.data.isCurrentlyParticipating,
          stipend: this.initialData.data.nomineeContactInfo.stipend,
          salaried: this.initialData.data.nomineeContactInfo.salaried,
          currentPayment: this.initialData.data.nomineeContactInfo.currentPayment,
          Family: this.initialData.data.nomineeContactInfo.family,
          Friends: this.initialData.data.nomineeContactInfo.friends,
          Coworkers: this.initialData.data.nomineeContactInfo.coworkers,
          Others: this.initialData.data.nomineeContactInfo.others,
          pleaseDescribe: this.initialData.data.nomineeContactInfo.pleaseDescribe
            ? this.initialData.data.nomineeContactInfo.pleaseDescribe
            : '',
          OrganizationWebsite: this.initialData.data.nomineeContactInfo.organizationwebsite,
          OrganizationRole: this.initialData.data.nomineeContactInfo.organizationrole,
          OrganizationState: this.initialData.data.nomineeContactInfo.organizationState,
          Organizationphone: this.initialData.data.nomineeContactInfo.organizationphone,
          Socialmedialink: this.initialData.data.nomineeContactInfo.socialmedialink,
          OrganizationName: this.initialData.data.nomineeContactInfo.organizationName,
          Organizationdescription: this.initialData.data.nomineeContactInfo.organizationdescription
            ? this.initialData.data.nomineeContactInfo.organizationdescription
            : '',
        },
      },
      yearsArray: [],
      currentYear: new Date(),
      compensation: 'NO_COMPENSATION',
      formReadyToGoNext: false,
      step: PERSONAL_PAGE,
      honoreeRelated: null,
      labelPleaseDescribe: 'Please Describe:',
      pleaseDescribeError: 'Other required',
      radiosSectionIsPristine: true,
      showRadioSectionErrorMessage: false,
      nomineeRelationOptions: [
        {
          id: 'Family',
          label: 'Family member',
          value: 'Family',
        },
        {
          id: 'Friend',
          label: 'Friend',
          value: 'Friend',
        },
        {
          id: 'Coworker_volunteer',
          label: 'Coworker and/or volunteer',
          value: 'Coworker_volunteer',
        },
        {
          id: 'Other',
          label: 'Other',
          value: 'Other',
        },
      ],
    };
  },
  computed: {
    radioSectionIsValid() {
      if (this.showRadioSectionErrorMessage) {
        return false;
      } else if (this.radiosSectionIsPristine && this.honoreeRelated?.length === 0) {
        return true;
      } else {
        return this.honoreeRelated?.length !== 0;
      }
    },
  },
  watch: {
    compensation(value) {
      switch (value) {
        case 'NO_COMPENSATION':
          this.formData.nomineeInfo.stipend = false;
          this.formData.nomineeInfo.salaried = false;
          break;
        case 'SALARY':
          this.formData.nomineeInfo.stipend = false;
          this.formData.nomineeInfo.salaried = true;
          break;
        default:
          this.formData.nomineeInfo.stipend = true;
          this.formData.nomineeInfo.salaried = false;
      }
    },

    honoreeRelated(value) {
      switch (value) {
        case 'Family':
          this.otherRelatedVisible = false;
          this.formData.nomineeInfo.Friends = 0;
          this.formData.nomineeInfo.Coworkers = 0;
          this.formData.nomineeInfo.Others = 0;
          this.formData.nomineeInfo.Family = this.nomineeRelationOptions[0].label;
          break;
        case 'Friend':
          this.otherRelatedVisible = false;
          this.formData.nomineeInfo.Friends = this.nomineeRelationOptions[1].label;
          this.formData.nomineeInfo.Coworkers = 0;
          this.formData.nomineeInfo.Others = 0;
          this.formData.nomineeInfo.Family = 0;
          break;
        case 'Coworker_volunteer':
          this.otherRelatedVisible = false;
          this.formData.nomineeInfo.Friends = 0;
          this.formData.nomineeInfo.Coworkers = this.nomineeRelationOptions[2].label;
          this.formData.nomineeInfo.Others = 0;
          this.formData.nomineeInfo.Family = 0;

          break;
        case 'Other':
          this.otherRelatedVisible = true;
          this.formData.nomineeInfo.Friends = 0;
          this.formData.nomineeInfo.Coworkers = 0;
          this.formData.nomineeInfo.Others = this.nomineeRelationOptions[3].label;
          this.formData.nomineeInfo.Family = 0;
          break;
        default:
          this.otherRelatedVisible = false;
          this.formData.nomineeInfo.Friends = 0;
          this.formData.nomineeInfo.Coworkers = 0;
          this.formData.nomineeInfo.Others = 0;
          this.formData.nomineeInfo.Family = 0;
      }
    },
    isSelf() {
      this.resetNominatorInfo();
    },
  },
  mounted() {
    for (let i = this.settings.firstYearDropdown; i <= this.currentYear.getUTCFullYear(); i++) {
      this.yearsArray.push({
        label: `${i}`,
        id: i,
        code: i,
      });
    }
    this.yearsArray.reverse();

    if (this.initialData.data.nomineeContactInfo.salaried) {
      this.compensation = 'SALARY';
    } else if (this.initialData.data.nomineeContactInfo.stipend) {
      this.compensation = 'STIPEND';
    } else {
      this.compensation = 'NO_COMPENSATION';
    }

    var relatedData = this.initialData.data.nomineeContactInfo;
    if (relatedData.family && relatedData.family.length > 1) {
      this.honoreeRelated = 'Family';
    } else if (relatedData.coworkers && relatedData.coworkers.length > 1) {
      this.honoreeRelated = 'Coworker_volunteer';
    } else if (relatedData.friends && relatedData.friends.length > 1) {
      this.honoreeRelated = 'Friend';
    } else if (
      (relatedData.others && relatedData.others.length > 1) ||
      relatedData.pleaseDescribe
    ) {
      this.honoreeRelated = 'Other';
      this.otherRelatedVisible = true;
    } else {
      this.honoreeRelated = '';
      this.otherRelatedVisible = false;
    }
  },
  methods: {
    updateRadiosSectionState() {
      this.radiosSectionIsPristine = false;
      this.showRadioSectionErrorMessage = false;
    },
    resetNominatorInfo() {
      this.formData.nominatorInfo.title = null;
      this.formData.nominatorInfo.firstName = '';
      this.formData.nominatorInfo.lastName = '';
      this.formData.nominatorInfo.homePhone = '';
      this.formData.nominatorInfo.workPhone = '';
      this.formData.nominatorInfo.fax = '';
      this.formData.nominatorInfo.addressLine1 = '';
      this.formData.nominatorInfo.addressLine2 = '';
      this.formData.nominatorInfo.city = '';
      this.formData.nominatorInfo.stateCode = null;
      this.formData.nominatorInfo.zip = '';
      this.formData.nominatorInfo.organizationName = '';
      this.formData.nominatorInfo.jobTitle = '';
      this.formData.nominatorInfo.email = '';
    },
    handleFormSubmit(e) {
      e.preventDefault();
      eventBus.emit(VALIDATE_CHILD_EVENT);

      nextTick().then(() => {
        const formValid = this.isSelf
          ? this.validateObject(this.formData.nomineeInfo)
          : this.validateObject(this.formData.nomineeInfo) &&
          this.validateObject(this.formData.nominatorInfo);

        if (formValid) {
          const data = this.gatherData(true);
          this.submitData(data);
        }
      });
    },
    triggerValidation() {
      eventBus.emit(VALIDATE_CHILD_EVENT);
    },

    handleFormSave() {
      eventBus.emit(VALIDATE_CHILD_EVENT);
      nextTick().then(() => {
        const data = this.gatherData();
        this.submitData(data);
      });
    },
    gatherData(incrementLocalStep = false) {
      const incrementAllowedStep = () => {
        return incrementLocalStep && this.step >= this.allowedSteps;
      };

      const data = {
        isSelf: this.isSelf,
        nominationId: this.formId,
        step: this.step,
        incrementLocalStep,
        incrementStep: incrementAllowedStep(),
        nomineeInfo: this.extractData(this.formData.nomineeInfo),
      };

      if (!this.isSelf) {
        data.nominatorInfo = this.extractData(this.formData.nominatorInfo);
      }

      return data;
    },
    extractData(object) {
      const keys = Object.keys(object);
      const data = {};
      keys.forEach((key) => {
        if (typeof object[key] === 'object') {
          data[key] = object[key].value;
        } else {
          data[key] = object[key];
        }
      });
      return data;
    },
    validateObject(object) {
      const keys = Object.keys(object);
      let formValid = true;

      keys.forEach((key) => {
        if (typeof object[key] === 'object' && !object[key].isValid) {
          formValid = false;
        }
      });

      if (!this.isSelf && !this.honoreeRelated.length) {
        formValid = false;
        this.showRadioSectionErrorMessage = true;
      }

      return formValid;
    },
    submitData(data) {
      axiosPostWrapper(this.settings.webserviceUrl, JSON.stringify(data)).then((response) => {
        if (data.incrementLocalStep) this.$emit('nextClick', QUESTIONS_PAGE);
        this.$emit('onDataSubmit', response);
      });
    },
  },
};
</script>

<style>
/* Enter and leave animations can use different */

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.25s ease;
}

.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
